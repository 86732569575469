import { Box, Center, Spinner } from "@chakra-ui/react";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import MapMarker from "./Marker";
import { useUrlParams } from "../../hooks/action/useUtils";
import { CITY_COORDINATES, MAP_INIT_OBJ } from "../../config/constant";

const Map = ({ vehicleData }: any) => {
  const [activeMarker, setActiveMarker] = useState<any>(null);
  const { getParams } = useUrlParams();

  const [mapCenter, setMapCenter] = useState({
    lat: 17.385,
    lng: 78.4867,
  });

  const { isLoaded } = useLoadScript(MAP_INIT_OBJ);

  if (!isLoaded)
    return (
      <Center>
        <Spinner size={"md"} />
      </Center>
    );

  const zoom = 8;

  const onLoad = async (map: google.maps.Map) => {
    console.log("Map: ", map);
    // await fetchFleetLocations(headerState.city);
    // fitBoundsAllowed.current = true
    // setMap(map);
  };

  return (
    <>
      <Box w={"auto"} h={"87vh"} shadow={"md"} borderRadius={"md"} overflow={"hidden"}>
        <GoogleMap mapContainerStyle={{ width: "100%", height: "100%" }} center={mapCenter} zoom={zoom} onLoad={onLoad} options={{ mapTypeControl: false }}>
          {/* {vehicleData &&
            Object.values(vehicleData)?.map((itm: any, index: number) => (
              <MapMarker key={index} {...itm} isActive={activeMarker && activeMarker.fleet_id === itm.fleet_id} onMarkerSelect={(item: any) => setActiveMarker(item)} />
            ))} */}
        </GoogleMap>
      </Box>
    </>
  );
};
export default Map;
