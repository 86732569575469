import {
  Box,
  Button,
  Divider,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  TagLabel,
  Text,
  useColorModeValue,
  useMediaQuery,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  WrapItem,
  Wrap,
} from "@chakra-ui/react";
import React, { useEffect, useLayoutEffect, useState } from "react";
import Map from "../../components/molecules/Map";
import { createColumnHelper } from "@tanstack/react-table";
import { Header } from "../../components/table/CaseListingTableComponent";
import dayjs from "dayjs";
import NetworkWrapper from "../../components/wrapper/NetworkWrapper";
import { DataTable } from "../../components/table/DataTable";
import {
  useEntityFilter,
  useSquadById,
  useSquadMapDetails,
} from "../../hooks/api/useEntityApi";
import { useNavigate, useParams } from "react-router-dom";
import { useUrlParams } from "../../hooks/action/useUtils";
import { objectToQueryStringOmitEmpty } from "../../utils";

export default function Nearby() {
  const [activeTabName, setActiveTabName] = useState("Map View");
  const [isMobileView] = useMediaQuery("(max-width: 768px)");
  const darkSlate = useColorModeValue("white", "slate.800");
  const { id: orderId } = useParams();
  const { getAllParams } = useUrlParams();
  const navigate = useNavigate();
  const [selectedValue, setOnSelectedValue] = useState({});
  const { data, rest } = useSquadMapDetails();

  const onTabSelect = (key: string) => {
    setActiveTabName(key);
  };

  const getFormattedName = (id: string) => {
    const isAuto = id?.includes("ad") || id?.includes("AD");
    return (
      <Flex>
        <Text fontSize={"xs"} fontWeight={"semibold"}>
          {isAuto ? "AUTO-" : "MANUAL-"}
        </Text>
        <Text fontSize={"xs"} fontWeight={"normal"}>
          {id}
        </Text>
      </Flex>
    );
  };

  useEffect(() => {
    if (data?.length > 0) {
      setOnSelectedValue(data[0]);
    }
  }, [data]);

  const onSelected = (itm: any) => {
    console.log("onselelct: ", itm);
    setOnSelectedValue(itm);
  };

  return (
    <NetworkWrapper status={rest}>
      <Box>
        {isMobileView ? (
          <Box>
            {activeTabName === "Map View" ? (
              <MapView data={selectedValue} />
            ) : (
              <Tabs variant="enclosed">
                <TabList bg="white" border={0} flexWrap={"wrap"}>
                  {data?.map((itm: any, index: number) => (
                    <Tab
                      key={index}
                      w={"full"}
                      _selected={{ bg: "green.200" }}
                      onClick={() => onSelected(itm)}
                    >
                      {getFormattedName(itm.entityId)}
                    </Tab>
                  ))}
                </TabList>
                <Divider />
                <TabPanels>
                  {data?.map((itm: any) => (
                    <TabPanel key={itm.entityId}>
                      <TableView data={selectedValue} />
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>
            )}
          </Box>
        ) : (
          <Box>
            <Flex>
              <Box flex={1} mr={4}>
                <MapView data={selectedValue} />
              </Box>
              <Box flex={1}>
                <Tabs
                  variant="enclosed "
                  bg={"white"}
                  maxW={["full", "40vw"]}
                  // overflowX={"scroll"}
                >
                  <TabList bg="white" border={0}>
                    <Wrap>
                      {data?.map((itm: any, index: number) => (
                        <WrapItem key={index}>
                          <Tab
                            _selected={{ bg: "green.200", borderRadius: "sm" }}
                            onClick={() => onSelected(itm)}
                          >
                            {getFormattedName(itm.entityId)}
                          </Tab>
                        </WrapItem>
                      ))}
                    </Wrap>
                  </TabList>
                  <Divider />
                  <TabPanels>
                    {data?.map((itm: any) => (
                      <TabPanel key={itm.entityId}>
                        <TableView data={selectedValue} />
                      </TabPanel>
                    ))}
                  </TabPanels>
                </Tabs>
              </Box>
            </Flex>
          </Box>
        )}
        <Box
          pos={"fixed"}
          left={"50%"}
          bottom={5}
          w={"auto"}
          zIndex={250}
          transform={"translate(-50%, -50%)"}
          display={["flex", "none"]}
        >
          <Tabs
            variant="soft-rounded"
            colorScheme="orange"
            size={"sm"}
            w={"full"}
            px={5}
            minW={280}
          >
            <TabList
              bg={"white"}
              borderWidth={1}
              shadow={"md"}
              py={2}
              w={"full"}
              borderRadius={"full"}
              justifyContent={"space-between"}
            >
              <Tab onClick={() => onTabSelect("Map View")}>Map View</Tab>
              <Tab onClick={() => onTabSelect("Live Events")} fontSize={"xs"}>
                Table View
              </Tab>
            </TabList>
          </Tabs>
        </Box>
      </Box>
    </NetworkWrapper>
  );
}

const MapView = ({ data }: any) => {
  const modData = data?.data ? JSON.parse(data.data)?.data?.pool : [];

  const modifyData = (data: any) => {
    const _processData = data
      ? data.map((itm: any) => ({
          ...itm,
          isReadMode: true,
          location: { lat: itm.lat, lng: itm.lng },
        }))
      : [];

    console.log("process: ", _processData, " modData:", modData);
    return _processData;
  };

  useEffect(() => {
    console.log("upate data: ", data);
  }, [data]);

  return (
    <Box>
      {modData?.length > 0 ? (
        <Map vehicleData={modifyData(modData)} />
      ) : (
        <Box>
          <Text>no data point found</Text>
        </Box>
      )}
    </Box>
  );
};

export const TableView = ({ data }: any) => {
  const columnHelper = createColumnHelper<any>();
  const modData: any = data?.data ? JSON.parse(data.data)?.data?.pool : [];
  // const [selectedData, setSelectedData] = useState({})
  // const { isOpen, onOpen, onClose } = useDisclosure()

  // const payload = {
  //   eventName: "",
  //   page: 1,
  //   serviceNameText: "ASSIGNMENT_SERVICE",
  //   sortOrder: "desc",
  //   traces: data?.entityId,
  // };

  // const { data: assgnmentService }: any = useEntityFilter({
  //   customTraces: data?.entityId,
  //   userPayload: payload,
  // });

  const [result, setResult] = useState([]);
  const { mutateAsync, ...props }: any = useSquadById({
    onSuccess: onResponseHandler,
  });

  const columns = [
    columnHelper.accessor("pilot_name", {
      cell: (info) => (
        <Flex direction={"column"}>
          <Tag variant={"subtle"} colorScheme="orange" mr={2} mb={2}>
            <TagLabel fontWeight={"semibold"} fontSize={"sm"}>
              <Flex align={"center"}>
                <Text ml={2} whiteSpace={"nowrap"} fontSize={"xs"}>
                  {info.getValue()}
                </Text>
              </Flex>
            </TagLabel>
          </Tag>
          <Tag my={0} variant={"outline"} colorScheme="orange" mr={2} mb={2}>
            <TagLabel fontWeight={"semibold"} fontSize={"sm"}>
              <Flex>
                <Text ml={2} fontSize={"xs"}>
                  {info.row.original?.pilot_mobile_num}
                </Text>
              </Flex>
            </TagLabel>
          </Tag>
        </Flex>
      ),
      header: () => <Header text={"*️⃣ Pilot Name"} />,
    }),

    // columnHelper.accessor("timestamp", {
    //   cell: (info) => (
    //     <Box px={3} borderRadius={"full"}>
    //       <Button>Events</Button>
    //     </Box>
    //   ),
    //   header: () => <Text align={"left"}>Events </Text>,
    // }),
    columnHelper.accessor("pickupDistance", {
      cell: (info) => (
        <Box px={3} borderRadius={"full"}>
          <Text whiteSpace={"nowrap"} fontWeight={"semibold"} fontSize={"xs"}>
            {parseFloat((info.getValue() / 1000).toString()).toFixed(2)} Km
          </Text>
        </Box>
      ),
      header: () => <Text align={"left"}>Distance </Text>,
    }),

    columnHelper.accessor("pickupEta", {
      cell: (info) => (
        <Box px={3} borderRadius={"full"}>
          <Text whiteSpace={"nowrap"} fontWeight={"semibold"} fontSize={"xs"}>
            {parseFloat((info.getValue() / 60).toString()).toFixed(1)} Mins
          </Text>
        </Box>
      ),
      header: () => <Text align={"left"}>ETA </Text>,
    }),
    // columnHelper.accessor("data", {
    //   cell: (info) => (
    //     <Code p={2} borderRadius={"md"} maxW={300}>
    //       <Text textTransform={"capitalize"} fontSize={"xs"}>
    //         {JSON.parse(info.getValue())?.message}
    //       </Text>
    //     </Code>
    //   ),
    //   header: () => <Text align={"left"}>📧 Message </Text>,
    // }),
    // columnHelper.accessor("data", {
    //   cell: (info) => <CodeRender code={info.getValue()} />,
    //   header: () => <Text align={"left"}>💻 Data</Text>,
    // }),
  ];

  function onResponseHandler(data: any) {
    const _result = data?.data?.data || [];
    const arr: any = [];
    _result.forEach((element: any, index: any) => {
      const obj = { ...element, ...modData[index] };
      arr.push(obj);
    });
    console.log("my resutl: ", arr);
    setResult(arr);
  }

  useEffect(() => {
    if (modData?.length > 0) {
      const payloadArr = modData.map((itm: any) => itm.entityId);
      if (payloadArr?.length > 0) {
        mutateAsync({ entityIds: payloadArr });
      }
    }
  }, [data]);

  return (
    <NetworkWrapper status={props}>
      <Box
        borderRadius={"md"}
        overflow={"scroll"}
        bg={"white"}
        shadow={"md"}
        className="custom-scrollbar"
      >
        {modData ? (
          <DataTable isDataLoading={false} columns={columns} data={result} />
        ) : (
          <Text fontWeight={"semibold"} fontSize={"sm"}>
            No Data Found
          </Text>
        )}
      </Box>
    </NetworkWrapper>
  );
};

const EventModal = ({ data, isOpen, onOpen, onClose }: any) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Modal Title</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {data.map((itm: any) => {
            <Box m={2} shadow={"md"}>
              <Flex>
                <Text>{itm.eventName}</Text>
                <Text>{JSON.parse(itm.data)?.message || ""}</Text>
              </Flex>
            </Box>;
          })}
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button variant="ghost">Secondary Action</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
